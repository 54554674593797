import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import ContactForm from './contact-form';

const icons = require.context('../assets/icons/', true);
const iconPath = (name) => icons(name, true);

export default function Contact() {
	return (
		<section id="kontakt" className="mlg_section mlg_contact mb-7rem">
			<Container>
				<Row>
					<Col lg={4} md={12} className="mb-md-48px">
						<div className="mlg_heading mb-3-5rem">
							<h2 style={{ fontSize: '50px', lineHeight: '65px' }}>Kontakt</h2>
						</div>

						<ContactForm />
					</Col>

					<Col lg={1} md={12}></Col>

					<Col lg={7} md={12} className="align-self-end">
						<div className="mlg_bg-gradient-3 mlg_has-seperator-top">
							<div className="mlg_contact-info">
								<div className="mlg_heading mlg_h-white">
									<h3>Jetzt sind Sie gefragt.</h3>
									<p className="pr-3rem pr-md-0px pr-sm-0px pr-xs-0px">
										Gehen Sie noch heute mit uns den Schritt in die Zukunft.
										<br />
										Wir erörtern mit Ihnen gemeinsam die Möglichkeiten unserer Lösung für Ihre Stadt
										oder Gemeinde, Ihr Unternehmen, Ihre Organisation – und das völlig unverbindlich
										und auf Sie zugeschnitten.
									</p>
								</div>

								<Row>
									<Col md={5} sm={12}>
										<div className="mlg_icon-with-text">
											<div className="mlg_iwt-icon">
												<img src={iconPath('./standort-icon.svg')} alt="Map Icon" />
											</div>

											<div className="mlg_iwt-text">
												<p>
													<strong>THNG GmbH</strong>
													<br />
													Römerstraße 75
													<br />
													71229 Leonberg
												</p>
											</div>
										</div>
									</Col>

									<Col md={7} sm={12}>
										<div className="mlg_icon-with-text">
											<div className="mlg_iwt-icon">
												<img src={iconPath('./e-mail-icon.svg')} alt="Email Icon" />
											</div>

											<div className="mlg_iwt-text">
												<p>
													<a href="mailto:info@thng.de">info@thng.de</a>
												</p>
											</div>
										</div>

										<div className="mlg_icon-with-text">
											<div className="mlg_iwt-icon">
												<img
													style={{ height: '36px', transform: 'translateX(3px)' }}
													src={iconPath('./tel-icon.svg')}
													alt="Telefon Icon"
												/>
											</div>

											<div
												className="mlg_iwt-text mb-sm-0px mb-xs-0px"
												style={{ lineHeight: '45px' }}>
												<p>
													<a href="tel:+4971529065990">+49 7152 / 90 65 99 0</a>
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
