import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const images = require.context('../assets/img/', true);
const imagePath = (name) => images(name, true);

export default function Introduction(data) {
	return (
		<section className="mlg_intro mlg_section mb-7rem">
			<Container>
				<Row>
					<Col lg={6} md={12}>
						<img src={imagePath('./' + data.image.src)} alt={data.image.alt} />
					</Col>

					<Col lg={6} md={12} className="mb-md-48px mb-sm-36px mb-xs-36px">
						<div className="mlg_heading mb-md-48px">
							<h2 className="mb-md-48px" dangerouslySetInnerHTML={{ __html: data.title }} style={{ fontSize: '50px', lineHeight: '65px' }} />
							<p
								className="pr-9rem pr-md-0px pr-sm-0px pr-xs-0px"
								dangerouslySetInnerHTML={{ __html: data.subtitle }}
							/>
						</div>

						{data.button && (
							<div className="mlg_btn mlg_b-inline">
								<a href={data.button.url} dangerouslySetInnerHTML={{ __html: data.button.text }} />
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
}
