import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ContactMessage from '../types/ContactMessage';
import service, { createService } from '../service/service';
import { Alert } from 'react-bootstrap';

export const AlertType = {
	info: 'info',
	success: 'success',
	error: 'danger',
};

export const alertMessage = {
	success: 'Erfolgreich eingereicht!',
	error: 'Fehler senden!',
};

export default function ContactForm() {
	const { control, watch, handleSubmit, reset } = useForm();

	const [alertData, setAlertData] = React.useState({ open: false, type: AlertType.info, message: '' });

	const values = watch();

	React.useEffect(() => {
		if (alertData.open) {
			setTimeout(() => {
				setAlertData({ open: false, type: AlertType.info, message: '' });
			}, 4000);
		}
	}, [alertData.open]);

	const onSubmit = React.useCallback((values) => {
		const message = new ContactMessage(values.name, values.email, values.message, values.phone, values.company);
		service.setAdapter(createService());
		service
			.sendContactInfo(message)
			.then(() => {
				setAlertData({ open: true, type: AlertType.success, message: alertMessage.success });
				reset();
			})
			.catch(() => setAlertData({ open: true, type: AlertType.error, message: alertMessage.error }));
	}, []);

	React.useEffect(() => {
		Object.entries(values).forEach((value) => {
			const input = document.querySelector(`.mlg_contact .mlg_contact-form #${value[0]}.mlg_cf-field`);
			if (value[1] === '') {
				input.classList.remove('focused');
				input.parentNode.classList.remove('focused');
			} else {
				input.classList.add('focused');
				input.parentNode.classList.add('focused');
			}
		});
	}, [values]);

	return (
		<>
			<form className="mlg_contact-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="mlg_cf-row required">
					<Controller
						as={<input required />}
						type="text"
						id="name"
						name="name"
						className="mlg_cf-field"
						control={control}
						defaultValue=""
						rules={{ required: true, minLength: 2 }}
					/>
					<label htmlFor="name">Name</label>
				</div>

				<div className="mlg_cf-row">
					<Controller
						as={<input />}
						type="text"
						id="company"
						name="company"
						className="mlg_cf-field"
						control={control}
						defaultValue=""
					/>
					<label htmlFor="company">Unternehmen / Organisation</label>
				</div>

				<div className="mlg_cf-row required">
					<Controller
						as={<input required />}
						type="email"
						id="email"
						name="email"
						className="mlg_cf-field"
						control={control}
						defaultValue=""
						rules={{ required: true }}
					/>
					<label htmlFor="email">E-Mail Adresse</label>
				</div>

				<div className="mlg_cf-row">
					<Controller
						as={<input />}
						type="tel"
						id="phone"
						name="phone"
						className="mlg_cf-field"
						control={control}
						defaultValue=""
					/>
					<label htmlFor="phone">Telefonnummer</label>
				</div>

				<div className="mlg_cf-row">
					<Controller
						as={<textarea required />}
						id="message"
						name="message"
						rows="1"
						className="mlg_cf-field"
						control={control}
						defaultValue=""
						rules={{ required: true }}
					/>
					<label htmlFor="message">Nachricht</label>
				</div>

				<div className="mlg_cf-footer">
					<p className="mlg_cf-required-desc">* Pflichtfeld</p>

					<div className="mlg_cf-submit">
						<button type="submit">Absenden</button>
					</div>
				</div>
			</form>
			<Alert show={alertData.open} variant={alertData.type} style={{ marginTop: '16px' }}>
				{alertData.message}
			</Alert>
		</>
	);
}
