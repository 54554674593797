class ContactMessage {
	public name: string;
	public email: string;
	public phone: string;
	public company: string;
	public message: string;

	constructor(name: string, email: string, message: string, phone: string, company: string) {
		this.name = name;
		this.email = email;
		this.message = message;
		this.phone = phone;
		this.company = company;
	}

	public toJSON() {
		return {
			name: this.name,
			email: this.email,
			phone: this.phone,
			organization: this.company,
			message: this.message,
		};
	}
}

export default ContactMessage;
