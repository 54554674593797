import { Service } from './service';
import ContactMessage from '../types/ContactMessage';
import PriceRequest from '../types/priceRequest';

class ServiceAdapterManager implements Service {
	private adapter: Service;

	constructor(adpater: Service) {
		this.adapter = adpater;
	}

	public setAdapter(adapter: Service) {
		this.adapter = adapter;
	}

	public sendContactInfo = async (data: ContactMessage) => {
		return this.adapter.sendContactInfo(data);
	};

	public priceRequest = async (data: PriceRequest) => {
		return this.adapter.priceRequest(data);
	};
}

export default ServiceAdapterManager;
