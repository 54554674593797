import ContactMessage from '../types/ContactMessage';
import PriceRequest from '../types/priceRequest';
import { Service, Instances } from './service';

class ServiceClient implements Service {
	constructor(private clientInstances: Instances) {}

	public async sendContactInfo(data: ContactMessage): Promise<any> {
		await this.clientInstances.contact.call('Contact', { message: data.toJSON() });
		return;
	}
	public async priceRequest(data: PriceRequest): Promise<any> {
		await this.clientInstances.contact.call('PriceRequest', { request: data.toJSON() });
		return;
	}
}

export default ServiceClient;
