import { Service } from './service';

class NotImplementedService implements Service {
	public sendContactInfo(): Promise<void> {
		throw new Error('not implemented service');
	}
	public priceRequest(): Promise<void> {
		throw new Error('not implemented service');
	}
}

export default NotImplementedService;
