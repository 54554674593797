import ContactMessage from '../types/ContactMessage';
import ServiceClient from './client';
import ServiceAdapterManager from './adapterManager';
import NotImplementedService from './notImplemented';
import { JSONRPCClient } from 'jsonrpc2-client';
import PriceRequest from '../types/priceRequest';

const service = new ServiceAdapterManager(new NotImplementedService());
const services = ['contact'];
export interface Instances {
	contact: JSONRPCClient;
}

export interface Service {
	sendContactInfo(data: ContactMessage): Promise<void>;
	priceRequest(data: PriceRequest): Promise<any>;
}
export function createService(): Service {
	const instances: any = services.reduce((acc, item) => {
		return { ...acc, [item]: new JSONRPCClient(process.env.GATSBY_API_URL) };
	}, {});
	const client = new ServiceClient(instances);
	return client;
}

export default service;
