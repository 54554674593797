import { Link } from 'gatsby';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';
import Typed from 'typed.js';

const images = require.context('../assets/img/', true);
const imagePath = (name) => images(name, true);

export default function Hero(data) {
	React.useEffect(() => {
		var $mlg_typing_effect = $('.mlg_typing-effect');

		$mlg_typing_effect.each(function () {
			var $this = $(this),
				id = 'mlg_te-' + Math.floor(Math.random() * 1000) + 1,
				words = $this.attr('data-words').split('|');

			$this.attr({ id: id });

			new Typed('#' + id, {
				strings: words,
				startDelay: 500,
				typeSpeed: 100,
				backSpeed: 100,
				backDelay: 2000,
				showCursor: true,
				cursorChar: '|',
				loop: true,
			});
		});
	}, []);

	return (
		<section className="mlg_site-hero mlg_section">
			<Container>
				<Row>
					<Col lg={5} md={12} className="align-self-center">
						<div className="mlg_heading">
							<h1 className="mb-1-5rem" dangerouslySetInnerHTML={{ __html: data.title }} />

							<p className="mb-2-5rem" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
						</div>
						{data.button && (
							<div className="mlg_btn mlg_b-primary">
								<Link to={data.button.url}>
									{data.button.text}
								</Link>
							</div>
						)}
					</Col>

					<Col lg={7} md={12} className="align-self-end text-lg-right text-md-center">
						<img src={imagePath('./' + data.image.src)} alt={data.image.alt} />
					</Col>
				</Row>
			</Container>
		</section>
	);
}
