import { IconButton } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { withPrefix } from 'gatsby';

const CookieConsent = () => {
	const [show, setShow] = React.useState(false);

	const handleOk = React.useCallback(() => {
		localStorage.setItem('cookies-accepted', 'true');
		setShow(false);
	}, []);

	React.useEffect(() => {
		if (localStorage.getItem('cookies-accepted') === null) {
			setShow(true);
		}
	}, []);

	return show ? (
		<div className="cookie-consent">
			<div className="cookie-consent-content">
				<span>
					Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis
					aus.
				</span>
				<div className="cookie-consent-buttons">
					<button className="cookie-consent-button" onClick={handleOk}>
						OK
					</button>
					<a href={withPrefix('/datenschutzerklarung')} target="_blank" className="cookie-consent-button">
						Datenschutzerklärung
					</a>
				</div>
			</div>

			<div className="cookie-consent-close-container">
				<IconButton className="cookie-consent-button-close" onClick={handleOk}>
					<CloseIcon className="cookie-consent-close-icon" />
				</IconButton>
			</div>
		</div>
	) : null;
};

export default CookieConsent;
